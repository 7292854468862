.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #B5B5B5;
  padding: 0% 6%;
  position: relative;
  background: white;
  z-index: 1;
}

.Navbar.NavbarBlack {
  background-color: #3d3d3d;
  border-bottom: none;
}

.Navbar .SiteLogo {
  width: 20rem;
}

.SiteLogo .ClubStoreButton {
  width: 100%;
}

.NavbarItems .items {
  display: flex;
}

.NavbarItems .items ul {
  display: flex;
  list-style: none;
  margin: 0;
}

.NavbarItems .items ul li {
  height: 95px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
}

.NavbarItems .items .ClubStoreButton button {
  padding: 10px 15px;
}

.NavbarItems .items .ClubStoreButton button:hover {
  outline: 1px dashed #808080;
  border-radius: 5px;
  /* color: #4B4B4B; */
}

.SearchButton {
  background-color: transparent !important;
  border: none !important;
  margin-top: 10px;
  cursor: pointer;
}

.SearchInputFieldWrapper {
  position: relative;
  width: 0;
  transition: all 0.6s ease;
  padding: 0;
  overflow: hidden;
}

.SearchInputField {
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
}

.SearchInputField:focus-visible {
  outline: none !important;
}

.SearchButtonWidth {
  width: 180px;
  transition: all 0.6 ease;
  padding: 2px 5px;
  font-size: 1.5rem;
  border-radius: 5px;
}

.SearchResultWrapper {
  position: absolute;
  top: 78px;
  background-color: white;
  border: 1px solid black;
  width: 250px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.SearchResultWrapper p {
  color: black;
  font-size: 1.2rem;
  border-bottom: 1px solid black;
  white-space: nowrap;
  padding: 12px 18px;
}

.SearchResultWrapper p:hover {
  background: #eeeeee;
}

.SearchResultWrapper p:last-child {
  border-bottom: none;
}

.AboutItem {
  position: relative;
}

.aboutDropdown {
  position: absolute;
  background-color: #F3F3F3;
  z-index: 999;
  top: 95px;
}

.aboutDropdown {
  display: none;
}

.AboutItem:hover .aboutDropdown {
  display: block;
}

.aboutDropdown ul {
  display: block !important;
  padding-left: 0px;
}

.aboutDropdown ul li:nth-child(1) {
  border-bottom: 1px solid #BABABA;
  border-top: 1px solid #BABABA;
}

.aboutDropdown ul li {

  /* padding: 5px 20px; */
  height: auto !important;
  margin: 0 !important;
  display: block !important;
}

.aboutDropdown ul li .aboutItemdropcontent {
  display: flex;
  align-items: center;
  padding: 8px 25px;
}

.aboutDropdown ul li:nth-child(3) img {
  width: 100%;
}

.aboutDropdown ul li svg {
  height: 16px;
}

.aboutDropdown ul li .ClubStoreButton button:hover {
  outline: none !important;
}

.aboutDropdown ul li svg,
.aboutDropdown ul li svg path {
  fill: #E5E5E5;
}

.aboutDropdown ul li .aboutItemdropcontent:hover svg,
.aboutDropdown ul li .aboutItemdropcontent:hover svg path,
.LeaderShip_Sub_Menu ul li:hover svg path {
  fill: black;
}

.aboutDropdown ul li .ClubStoreButton button:hover {
  color: black !important;
}

.LeaderShip_Sub_Menu ul li {
  display: flex !important;
  padding: 8px 25px 8px 68px;
}


.SolutionDropdown {
  position: absolute;
  width: 90%;
  display: flex;
  right: 5%;
  top: 100px;
  z-index: 999;
  background: #FAFAFA;
  padding: 0px 5px;
}

.SolutionDropdown .SolutionSliderEachItem {
  padding: 10px 5px;
}

/* .SolutionDropdown .SolutionSliderEachItem  p
{
  height: 55px ;
}
.SolutionDropdown .SolutionSliderEachItem  img
{
  margin-bottom: 25px;
} */
.SolutionDropdown {
  display: none;
  position: absolute;
  top: 96px;
  right: 5%;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-name: slideIn;
}

@keyframes slideIn {
  from {
    top: 5%;
  }

  to {
    top: 100;
  }
}

.SolutionItem:hover .SolutionDropdown {
  display: flex;
  animation-name: slideIn;
}





/* Mobile menu */
.mobileMenu {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;

}

/* hamburger */
.checkbox {
  display: none;
}

.button {
  position: relative;
  background-color: white;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  z-index: 2000;
  /* box-shadow: 0 1px 3px black; */
  text-align: center;
  cursor: pointer;
}

.background {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: fixed;
  background-image: radial-gradient(black, white);
  z-index: 1000;

  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.nav {
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.4s ease;
}

.list {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  list-style: none;
  text-align: center;
  width: 100%;
  margin-top: 25vh;
  padding-left: 0;
}

.item {
  margin: 2rem;
  font-size: 36px;
  border-bottom: 1px solid white;
  padding-bottom: 15px;
}

.link:link,
.link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(120deg,
      transparent 0%,
      transparent 50%,
      white 50%);
  background-size: 220%;
  transition: all 0.4s;
}

.link:hover,
.link:active {
  color: lightgreen;
  cursor: pointer;
}

/* functionality */
.checkbox:checked~.background {
  transform: scale(80);
}

.checkbox:checked~.nav {
  opacity: 1;
  width: 100%;
  right: 0;
}

/*  */
/* styling hamb ICON */
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon,
.icon::before,
.icon::after {
  width: 3rem;
  height: 2px;
  background-color: gray;
  display: inline-block;
}

.icon::before,
.icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.icon::before {
  top: -0.8rem;
}

.icon::after {
  top: 0.8rem;
}

.button:hover icon:before {
  top: -1rem;
}

.button:hover icon::after {
  top: 1rem;
}

.checkbox:checked+.button .icon {
  background-color: transparent;
}

.checkbox:checked+.button .icon::before {
  top: 0;
  transform: rotate(135deg);
}

.checkbox:checked+.button .icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.nav .item button {
  background-color: transparent !important;
  border: none !important;
  text-align: left;
}

.nav .item .ClubStoreButton {
  text-align: left;
  padding: 0px 15px;
}

.ButtonWithDrop svg {
  rotate: 90deg;
  width: 12px;
}

.ButtonWithDrop svg path {
  fill: white;
}

.ButtonWithDrop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  color: white;
  background: transparent;
  font-size: 3.5rem;
  font-weight: 600;
  width: 100%;



}

.nav .item ul {
  list-style: none !important;
  display: inline-block;
  text-align: left;
  padding-left: 40px;
}

.aboutItemdropcontent {
  display: flex;
  align-items: center;
}

.aboutItemdropcontent svg {
  height: 15px;
  fill: #797979;
}

.aboutItemdropcontent .ClubStoreButton {
  margin-left: 15px;
}

.aboutItemdropcontent .ClubStoreButton a {
  display: flex;
}

.nav .item ul li:nth-child(1) {
  margin: 15px 0px 15px 0px;
}

.nav .item ul li {
  margin: 15px 0px 15px 0px;
}

.SearchButtonMobile,
.SearchMenu {
  display: none;
}

@media screen and (max-width: 1640px) {


  .NavbarItems .items .ClubStoreButton button {
    padding: 10px 10px;
  }

  .NavbarItems .items ul li {
    margin: 0px 5px;
  }

  .Navbar {
    padding: 0% 5%;
  }
}

@media screen and (max-width: 1024px) {
  .NavbarItems .items ul li {
    margin: auto;
  }

  .NavbarItems .items .ClubStoreButton button {
    padding: 10px 10px;
  }

  .NavbarItems .items .ClubStoreButton button {
    margin: 0px 0px 0px 0px !important;
  }

  .News1,
  .News2 a {
    display: block !important;
  }

  .smallVideo {
    margin-top: 25px;
  }
}


@media screen and (max-width: 992px) {
  .NavbarItems {
    display: none;
  }

  .mobileMenu {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .MobaboutDropdown,
  .MobSolutionDropdown,
  .MobMediaDropdown {
    text-align: left;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease;

  }

  .MobaboutDropdown.open {
    height: 80px;
    transition: all 0.5s ease;
  }

  .MobSolutionDropdown.open {
    height: 80px;
    transition: all 0.5s ease;
  }

  .MobMediaDropdown.open {
    height: 48px;
    transition: all 0.5s ease;
  }

  .SearchButtonMobile {
    display: block;
    background: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 75px;
    cursor: pointer;
  }

  .SearchMenu {
    display: block;
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    height:0vh;
    z-index: 9999;
    left: 0;
    overflow: hidden;
}

/* When SearchMenu is opened */
.SearchMenu.SearchMenuHeight {
    animation: slideHeightUp 0.5s ease forwards;
}
/* Closing Transition */
.SearchMenuHide {
  animation: slideHeightDown 0.5s ease forwards;
}

@keyframes slideHeightUp {
    from {
        height: 0vh;
    }
    to {
        height: 100vh;
    }
}

@keyframes slideHeightDown {
    from {
        height: 100vh;
    }
    to {
        height: 0vh;
    }
}


  .SearchMenu .ClubStoreButton {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .MobileSearchMenu {
    margin: 150px 0px 0px 50px;
  }

  .MobileSearchMenu input {
    border: none;
    font-size: 3rem;
    font-weight: 600;
    margin-left: 10px;
  }

  .SearchInputWrapper {
    position: relative;
  }

  .SearchInputWrapper>div:nth-child(1) {
    display: flex;
    align-items: center;
  }

  .SearchResultWrappere {
    width: 80%;
    margin-top: 25px;
    position: absolute;
    background-color: white;
    height: 50vh;
    overflow: auto;
  }

  .SearchResultWrappere p {
    font-size: 2rem;
    padding-bottom: 10px;
  }

  .QuickLinks {
    margin-top: 50px;
  }

  .QuickLinks a {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: #7A7A7A;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  .QuickLinks a svg {
    width: 18px;
    height: 25px;
    margin-right: 15px;
  }

  .QuickLinks a svg path {
    color: black;
  }

  .QuickLinks p {
    font-size: 3rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 25px;
  }


}


@media screen and (max-width: 767px) {
  .NavbarItems {
    display: none;
  }

  .mobileMenu {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .SiteLogo .ClubStoreButton {
    width: 75%;
  }



}

@media screen and (max-width: 601px) {
  .News2 {
    margin-left: 0px !important;
    margin-top: 25px !important;
  }

  .Navbar {
    padding: 4% 5%;
  }
}