.joinus .ClubStoreButton button img
{
    height: 35px;
}
.footercontent
{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
}
.Footer
{
    padding: 3% 5%;
    z-index: 9;
    position: relative;
}
.Footer .SiteLogo .joinus .ClubStoreButton
{
    margin-right: 10px;
    width: auto !important;
}
.footerItems
{
    display: flex;
    
}
.joinus
{
    margin-right: 150px;
}
.socialMedia
{
    display: flex;
    margin-top: 15px;
}
.joinus .ClubStoreButton button img
{
    width: 30px;
}
   
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) 
{
    .footercontent {
        display: block;
    }
    .joinus
    {
        margin-top: 55px;
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
    .footercontent {
        display: block;
    }
    
    .joinus .ClubStoreButton button
    {
        margin: 5px 5px 0px 0px !important;
    }
    
    .footerItems .col1 .ClubStoreButton button
    {
        margin: 0px 10px 10px 0px !important;
    }
    .footerItems
    {
        margin: 45px 0px;
    }
    .joinus .ClubStoreButton button img {
        width: 25px;
    }
    .joinus
    {
        margin-top: 35px;
    }
    .socialMedia {
        margin-top: 3px;
    }
    .footerItems
    {
        flex-wrap: wrap;
    }
}