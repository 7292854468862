.PageDescription3
{
    display: flex;
    padding: 3% 5%;
    justify-content: space-between;
}
.PageDescription3 .col1,.PageDescription3 .col2,.PageDescription3 .col3
{
    width: 28%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
    .PageDescription3
    {
        display: block;
    }
    .PageDescription3 .col1
    {
    display: none;
    }
    .PageDescription3 .col2
    {
        width: 100%;
    }
    .PageDescription3 .col3
    {
        width: 100%;
    }
    .PageDescription3 .col3 img
    {
        margin-top: 25px;
        width:100%;
    }
}

