/* .BannerImage
{
    height:90vh;
    overflow:hidden;
    position: relative;
} */
/* .BannerImage img
{
    transform: translateY(-80px);
} */
.BannerContentOuter {
    /* position: absolute; */
    /* top: 0; */
    /* width: 100%; */
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BannerContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BannerButtons {
    display: flex;
}

.BannerContent p {
    text-align: center;
}

.HowItWork {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0% 5%;
}

.workImagesInner {
    margin: 100px 0px;
}

.HowItWork {
    text-align: center;
}

.WorkImages {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.WorkImages div {
    text-align: -webkit-center;
    width: 33%;
}

.WorkImages div p {
    text-align: center;
    width: 80%;
}

.IotPagesSliderBg {
    background-color: #F9F9F9;
    padding: 5rem 0rem;
    margin-bottom: 10rem;
}
.IotPagesSlider
{
    position: relative;
}
.IotPagesSlider .swiper-button-next, .IotPagesSlider .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 40%);
}
.IotPagesSlider .swiper-button-next:after, .IotPagesSlider .swiper-button-prev:after {
    color: black;
}
.IotPagesSlider,
.CommunicationTechInner,
.SolutionWrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0% 5%;
}

.SolutionWrapper {
    max-width: 1330px;

}

.SolutionHead {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 5rem;
}

.SolutionContent img {
    width: 100%;
}

.SolutionDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}

.SolutionDetails p:nth-child(1) {
    font-size: 2rem;
    font-weight: 800;
}

.SolutionDetails p:nth-child(2) {
    margin-left: 2rem;
    font-size: 1.5rem;
    line-height: 1.6;
    width: 60%;
    padding-top: 4px;

}

.CommunicationTech {
    background-color: black;
    padding: 10rem 5rem 12rem 5rem;
}

.DynaSysNetworksPlatform.CommunicationTech {
    margin-top: 10rem;
}

.CommunicationTechInner p:nth-child(1) {
    font-size: 6rem;
    color: white;
    font-weight: 600;
}

.CommunicationTechInner p:nth-child(2) {
    font-size: 2rem;
    color: white;
    font-weight: 600;
    margin: 2rem 0rem 6rem 0rem;
    width: 70%;
}

.DynaSysNetworksPlatform .CommunicationTechInner p:nth-child(2) {
    margin: 2rem 0rem 2rem 0rem;
}

.CommunicationImages {
    display: flex;
    flex-wrap: wrap;
}

.CommunicationImages div {
    width: 16.5%;
}
.CommunicationImages div:nth-child(5)
{
    width: 12%;
}

.Solution1 {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
}

.SolitionDetails {
    width: 45%;
}

.SolitionImage {
    width: 40%;
}

.SolitionImage img {
    width: 100%;
}
.ComprehensivePlatform 
{
    margin-bottom: 8rem;
}
.ComprehensivePlatform .SolitionDetails {
    width: 40%;
}

.ComprehensivePlatform .SolitionImage {
    width: 50%;
}

.SolitionDetails>p:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2;
}

.SolitionDetails>p:nth-child(2) {
    font-size: 2.4rem;
    color: black;
    font-weight: 400;
    margin: 2rem 0rem 2rem 0rem;
}

.ComprehensivePlatform .SolitionDetails>p:nth-child(2) {
    margin: 3rem 0rem 3rem 0rem;

}

.SolutionProperties p {
    font-size: 1.6rem;
    color: #555555;
    font-weight: 600;
    margin: 2rem 0rem 0rem 0rem;
}

.SolutionProperties p:hover {
    color: black;
}

.ComprehensivePlatform .SolutionProperties p {
    color: black;
    font-weight: 400;
    font-size: 2rem;
}

.ComprehensivePlatform .SolutionProperties p:hover {
    color: black;
}

.SolutionProperties p span {
    margin-right: 8px;
}

.SolutionWrapper .BannerButtons {
    margin-top: 4rem;
}

.SolutionWrapper .BannerButtons .CatalougeLink,
.SolutionWrapper .BannerButtons .OurPlatformLink {
    font-size: 1.8rem;
    font-weight: 400;
    border: 1px solid black;
    padding: 1.5% 0%;
    margin: 0% 0% 2% 15px;
    border-radius: 8px;
    width: 200px;
    cursor: pointer;
    text-align: center;
}

.SolutionWrapper .BannerButtons .CatalougeLink {
    background: black;
    color: white;
    padding: 1.5% 0%;

}

.SolutionWrapper .BannerButtons .OurPlatformLink {
    background-color: #F3F3F3;
    color: black;
}

.BannerContentOuter {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.CitySense .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/citySenseBanner.webp);
}

.DynaFleet .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/dynafleetBAnner.webp);
}

.Dynalock .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/DynalockBanner.png);
}

.DynaTrace .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/DynatraceBanner.webp);
}

.EcoClean .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/EcoCleanBanner.webp);
}

.IntelliPark .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/InteliparkBAnner.webp);
}

.SmartSentry .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/SmartSecurity.webp);
}

.TeraMonitor .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/TerramonitorBanner.png);
}

.UMeter .BannerContentOuter {
    background-image: url(../../../public//Images/IotPagesImages/UmeterBanner.png);
}

.SatelliteCommunications .BannerContentOuter {
    background-image: url(../../../public//Images/SatelliteCommunicationsImages/SatellitePageBanner.webp);
}

.Flyaway .BannerContentOuter {
    background-image: url(../../../public//Images/SatelliteCommunicationsImages/flyawayBanner.png);
}

.SatcomAntenna .BannerContentOuter {
    background-image: url(../../../public//Images/SatelliteCommunicationsImages/SatcomAntena.webp);
}

.Satcom_Flyaway_Antenna .BannerContentOuter {
    background-image: url(../../../public//Images/SatelliteCommunicationsImages/SatcomFlyawayAntena.webp);
}

.swiper-wrapper {
    z-index: 0 !important;
}


/* *******************************************************************************
***************************** IOT PAGE MEDIA QUERIES  ************************************
***************************************************************************************** */

@media only screen and (max-width: 1350px) {
    .CommunicationImages {
        justify-content: center;
    }

    .CommunicationImages div {
        margin-right: 5rem;
        margin-bottom: 5rem;
        width: 18%;
    }

    .CommunicationImages div img {
        width: 100%;
    }

    .IotSliderText {
        height: 155px;
    }

    .KeyFeatures div {
        width: calc(33% - 60px) !important;
        padding: 0px 30px !important;
    }

    .RadiationPatterns div {
        display: flex;
        justify-content: center;
        margin-bottom: 10rem;
    }

    .RadiationPatterns div img {
        width: 40%;
    }
    .CommunicationImages div:nth-child(5) {
        width:20%;
    }

}

@media only screen and (max-width: 992px) {
    .BannerButtons button {
        width: 150px;
    }

    .CommunicationImages div {
        width: 20%;
    }

    .SpecificationRow div {
        width: calc(33% - 30px) !important;
        padding: 0px 30px 0px 0px !important;
    }

    .EachSpecification p {
        white-space: wrap !important;
    }
}

@media only screen and (max-width: 768px) {
    .BannerButtons button {
        width: 120px;
    }

    .SpecificationRow div {
        width: calc(50% - 30px) !important;
        padding: 0px 30px 0px 0px !important;
    }
}

@media only screen and (max-width: 600px) {
    .BannerContent {
        width: 90%;
    }

    .BannerButtons button {
        width: 140px;
        padding: 8% 0%;
    }

    .SolutionWrapper .BannerButtons .CatalougeLink,
    .SolutionWrapper .BannerButtons .OurPlatformLink 
    {
        padding: 8px 0%;
    }

    .workImagesInner {
        margin: 50px 0px;
    }

    /* .Solution1
    {
        padding-top: 0px;
    } */

    .Solution1,
    .WorkImages {
        flex-direction: column;
        align-items: center;
    }

    .SolutionWrapper.SolutionWrapperRight .Solution1 {
        flex-direction: column-reverse;
    }

    .SolitionImage,
    .SolitionDetails {
        width: 100%;
    }

    .SolitionDetails {
        margin-top: 5rem;
    }

    .WorkImages div {
        width: 100%;
    }

    .WorkImages div:nth-child(2) {
        margin: 5rem 0rem;
    }

    .CommunicationTech {
        padding: 5rem 0rem 6rem 0rem;
    }

    .CommunicationTechInner p:nth-child(1) {
        font-size: 4rem;
    }

    .CommunicationTechInner p:nth-child(2) {
        width: 100%;
    }

    .CommunicationImages {
        justify-content: center;
    }

    .CommunicationImages div,
    .CommunicationImages div:nth-child(5) {
        width: 30%;
    }

    .ComprehensivePlatform .SolitionDetails {
        width: 100%;
    }

    .ComprehensivePlatform .SolitionDetails {
        width: 100%;
    }

    .ComprehensivePlatform.SolutionWrapper .Solution1 {
        margin-top: 2rem;
    }

    .ComprehensivePlatform .SolitionImage {
        margin-top: 10rem;
    }

    .ComprehensivePlatform .SolitionImage {
        width: 90%;
    }

    .IotSliderText {
        height: 140px;
    }

    .IotSliderText p {
        padding-left: 25px !important;
    }

    .BannerContent p:nth-child(1) {
        font-size: 4rem;
    }

    .IotPagesSliderBg {
        padding: 5rem 0rem 10rem 0rem;
    }

    .KeyFeatures div {
        width: calc(50% - 60px) !important;
    }

    .OurExpertiseSupport>p {
        padding-top: 10%;
    }

    .KeyFeatures,
    .TechnicalSpecifications,
    .RadiationPatterns,
    .Applications {
        padding: 8% 5% !important;
    }

}


@media only screen and (max-width: 425px) {
    .SpecificationRow div {
        width: calc(100%) !important;
        padding: 0px 30px 0px 0px !important;
    }

    .RadiationPatterns div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .RadiationPatterns div img {
        width: 80%;
    }
}