.SatelliteCommunications .ClubStoreButton:nth-child(1),
.SatelliteProduct .SolutionDetails p:nth-child(2),
.SatelliteCommunications .SolutionWrapper .ClubStoreButton,
.Flyaway .ClubStoreButton:nth-child(1),
.SatcomAntenna .ClubStoreButton:nth-child(1),
.Satcom_Flyaway_Antenna .ClubStoreButton:nth-child(1)
{
    display: none;
}
.OurExpertiseSupport,
.KeyFeatures
{
    background-color: black;
}
.OurExpertiseSupportInner
{
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    padding: 5% 5%;
}
.KeyFeatures,
.TechnicalSpecifications,
.RadiationPatterns,
.Applications
{
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    padding: 5% 5%;
}
.KeyFeatures,
.SpecificationRow
{
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.KeyFeatures div
{
    width: calc(33% - 180px);
    margin-bottom: 6rem;
    padding: 0px 80px;
}
.Flyaway .BannerImage img,
.SatcomAntenna .BannerImage img,
.Satcom_Flyaway_Antenna .BannerImage img
{
    transform: translateY(0px);
}
.SpecificationInner
{
    margin-top: 3rem;
}
.EachSpecification
{
    margin-top: 1rem;
}
.SpecificationRow div
{
    width: calc(33.3%);
    margin-bottom: 6rem;
    padding: 0px 0px 35px 0px;
    border-bottom: 1px solid #E3E3E3;
}
.EachSpecification p
{
    white-space: nowrap;
}
.RadiationPatterns

{
    padding: 0% 5% 0% 5%;
}
.Applications
{
    display: flex;
    align-items: center;
}
.Applications img
{
    margin-right: 10rem;
}
.Applications .SolutionProperties p
{
    text-align: left;
}
.SatelliteCommunications .borderSec,
.Flyaway .borderSec,
.Satcom_Flyaway_Antenna .borderSec,
.SatcomAntenna .borderSec
{
margin-top: 3%;
}