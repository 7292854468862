.CareerPage,
.CareerOpenings
{
    max-width: 1600px;
    margin: 0 auto;
}

.CareerPage .SolutionSlider
{
    margin-top: 12rem;
}
.CareerPage .PageDescription,
.CareerOpenings .PageDescription
{
    padding: 3% 5% !important;
}
.CurrentOpeningsButton
{
    padding-left: 5%;
    padding-right: 5%;
}
.CareerCulture
{
    margin-bottom: 8rem;
}