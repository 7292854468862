.PressReleaseWraper .Page_Body_wraper
{
    padding-bottom: 150px;
}
.Press_Releases_List
{
    padding: 0% 5%;
}
.PressReleaseWraper .Press_Releases_List
{
    margin-top: 45px;
    border-bottom: 1px solid #6C6C6C;;
    padding-bottom: 50px;
}
.press_release_container .Press_Releases_List:nth-last-child(1)
{
    border-bottom: none !important;
}
.press_Release_one
{
    display: flex;
}
.press_release_content
{
    margin-left: 100px;
    margin-top: 10px;
}
.press_release_content .ClubStoreButton a button:hover
{
    background-color: black !important;
    color: white !important;
}
.press_release_content p:nth-child(2)
{
    width: 70%;
}
.press_Image img
{
    /* image-rendering: pixelated !important; */
    width:300px;
    padding: 10px;
}
.press_Image_mobile
{
    display: none;
}

@media only screen and (max-width: 600px) 
{
    .press_Image
    {
        display: none;
    }
    .press_Image_mobile
{
    display: block;
}
.press_Image_mobile img
{
    width: 94.5%;
    padding: 10px;

}

    .press_release_content
    {
        margin-left: 0px !important;
    }
    .press_Release_one {
        display: block !important;
    }
    .press_release_content p:nth-child(2)
{
    width: 90%;
}
}
