.LandingpageNewsSlider
{
    max-width: 1450px;
    margin: 0 auto;
}
.NewsSlider
{
    padding: 12px 5% 15px 5%;
    position: relative;
    background: #F1F2F3;
}
.NewsText
{
    width: 55%;
}
.News_Image 
{
    margin-left: 25px;
}
.News_Image  img
{
    image-rendering: pixelated !important;
}


    .LandingpageNewsSlider .swiper-button-next, .LandingpageNewsSlider .swiper-button-prev {
       
        background-color: white;
    }
  
    .LandingpageNewsSlider .swiper-button-next.swiper-button-disabled,  .LandingpageNewsSlider  .swiper-button-prev.swiper-button-disabled {
        border: 1px solid black;
    }

    @media only screen and (min-width: 1800px)
    {
    
        .swiper-button-prev, .swiper-rtl .swiper-button-next {
            left: var(--swiper-navigation-sides-offset,5%);
            right: auto;
        }
        .swiper-button-next, .swiper-rtl .swiper-button-prev {
            right: var(--swiper-navigation-sides-offset,5%);
            left: auto;
        }

        .IotPagesSlider .swiper-button-prev, .swiper-rtl .swiper-button-next {
            left: var(--swiper-navigation-sides-offset,0%);
            right: auto;
        }
        .IotPagesSlider .swiper-button-next, .swiper-rtl .swiper-button-prev {
            right: var(--swiper-navigation-sides-offset,0%);
            left: auto;
        }


    }
    
    @media only screen and (max-width: 1350px)
    {
        .LandingpageNewsSlider {
            max-width: 882px;
            margin: 0 auto;
        }
        .swiper-button-prev, .swiper-rtl .swiper-button-next {
            left: var(--swiper-navigation-sides-offset,5%);
            right: auto;
        }
        .swiper-button-next, .swiper-rtl .swiper-button-prev {
            right: var(--swiper-navigation-sides-offset,5%);
            left: auto;
        }
    }
    @media only screen and (max-width: 1100px)
    {
        .LandingpageNewsSlider {
            max-width: 770px;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 992px)
{
    .LandingpageNewsSlider {
        max-width: 550px;
        margin: 0 auto;
    }
    .NewsSlider {
        padding: 12px 25px 15px 35px;
    }
    .NewsSlider
{
    padding: 12px 25px 15px 5%;
}


}

@media only screen and (max-width: 768px)

    {
        .LandingpageNewsSlider {
            max-width: 450px;
            margin: 0 auto;
        }
   
    .NewsWraper .News_Container {
        flex-direction: column;
    }
    
    .News_Image
    {
        margin-left: 0px;
        margin-top: 35px;
    }
    }

    
@media only screen and (max-width: 576px)
{

.News_Image img
{
    width: 95% !important;
}
.LandingpageNewsSlider {
    max-width: 215px;
    margin: 0 auto;
}

}
