@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
label,
span,
button {
  font-family: 'Space Grotesk', sans-serif !important;
}
p{
  margin: 0px;
}
/* .App
{
  overflow: hidden;
} */
/* html {
  overflow-x: hidden;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;                                    
} */
a {
  color: inherit;
  text-decoration: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.Page_Body_wraper
{
    max-width: 1600px;
    margin: 0 auto;
}

/* Base styles for all devices */

@media screen and (max-width: 350px) {
  html {
    font-size: 35%;
  }
}

/* Styles for mobile */
@media (min-width: 350px) {
  /* Styles for mobile go here */

  html {
    font-size: 48%;
  }
}

/* Styles for small tablets */
@media screen and (min-width: 768px) {
  /* Styles for small tablets go here */

  html {
    font-size: 52%;
  }
}

/* Styles for tablets */
@media screen and (min-width: 1024px) {
  /* Styles for tablets go here */

  html {
    font-size: 55%;
  }
}

/* Styles for laptops and desktops */
@media screen and (min-width: 1450px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 62.5%;
  }
}
/* Styles for laptops and desktops */
@media screen and (min-width: 1920px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 72.5%;
  }
}

/* Styles for large screens */
@media screen and (min-width: 2060px) {
  /* Styles for large screens go here */

  html {
    font-size: 78.5%;
  }
}

body {
  background-color: white;
  padding:0;
  margin:0;
}
