.headerSec {
    padding: 3% 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68vh;
    position: relative;
}

.headerSec .headerText p:nth-child(1) span {
    color: #b8b8b8 !important;
}

.headerSec .headerText .ClubStoreButton {
    cursor: pointer;
    display: inline-block;
    margin: 0px 0px 0px 0px !important;
    position: relative;
    height: 20px;
    width: 112px;
    margin-top: 35px;
}

.headerSec .headerText .ClubStoreButton button {
    /* background: linear-gradient(90deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 78.24%); */
    border-radius: 10px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    outline: 1px dashed white;
}

.headerSec .headerText .ClubStoreButton button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 0%, #B53598 78.24%);
    transition: left 0.25s ease;
    z-index: -1;
}

.headerSec .headerText .ClubStoreButton button:hover::before {
    left: 0;
}

.headerSec .headerText .ClubStoreButton button:hover {
    background-position: 0% center;
}

.smallVideo {
    display: flex;
    align-items: end;

}

.smallVideo img {
    border-radius: 12px;
}

.smallVideo .playBtn {
    background-color: #545454;
    padding: 14px 15px 8px 15px;
    border-radius: 25px;
    display: inline-block;
    margin-left: 15px;
    display: none !important;
}

.Media_News_Sec {
    display: flex;
    justify-content: end;
    padding: 1% 5%;
    background: #F1F2F3;
}

.News1,
.News2 a {
    display: flex;
}

.News2 {
    margin-left: 80px;
}

.videoText {
    margin: 15px 50px 0px 0px;
}

.videoText p {
    cursor: pointer !important;
}

.DynasysNetworkSecHead {
    padding: 0% 5%;
}

.DynasysNetworkImg {
    position: relative;
}

.dunasysnetworksText2 {
    position: absolute;
    top: 15%;
    left: 4%;
}

.dunasysnetworksText {
    display: none;
}

.DynasysNetworkBtnSec,
.whoWeareBtn,
.leaderBtn {
    display: flex;
    background: black;
    margin-top: -3px;
}

.whoWeareBtn {
    border-right: 1px solid #FFFFFF;
}

.whoWeareBtn .ClubStoreButton button,
.leaderBtn .ClubStoreButton button {
    transition: transform 0.3s;
}

.whoWeareBtn .ClubStoreButton button:hover,
.leaderBtn .ClubStoreButton button:hover {
    transform: scale(1.2);
    font-weight: 600;
}

.whoWeareBtn,
.leaderBtn {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 35px 0px;
    background: linear-gradient(180deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 150%);

}

.whoWeareBtn:hover,
.leaderBtn:hover {
    background: linear-gradient(180deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 100%);

}

.SolutionSlider {
    padding: 0% 5% 3% 5%;
    position: relative;
    margin-bottom: 5rem !important;
}

.swiper,
swiper-container {
    position: inherit !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: white;
}

.swiper-button-next,
.swiper-button-prev {
    padding: 0px 12px !important;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8;
    background-color: #ffffff73 !important;
    top: var(--swiper-navigation-top-offset, 60%);

}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0.55 !important;
    pointer-events: auto;
}

.swiper-container .swiper-button-next {
    right: -25px !important;
}

.swiper-container .swiper-button-prev {
    left: -25px !important;
}

.borderSec .border {
    border: 1px solid #A3A3A3;
}

.VerticalsSecHead {
    padding: 0% 5% 0% 5%;
}

.VerticalsSec {
    padding: 0% 5% 25px 5%;
    display: flex;
    justify-content: space-between;
}

.verticaleactItem {
    width: 22%;
}

.verticaleactItem p {
    font-size: 1.6rem !important;
}

.borderSec {
    display: flex;
    justify-content: center;
}

.border {
    width: 92%;
}




video {
    width: 100%;
    height: auto;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

#preloader-inner {
    text-align: center;
}

#preloader-inner span {
    font-size: 24px;
}

.preloaderbor {
    position: relative;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.preloadercontent {
    position: relative;
    z-index: 1;
    text-align: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}



/* NEW LANDING STYLES  */
.NewLanding {
    height: 88.5vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.NewLandingHero {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 75%;
}

.LandingUpdatesWrapper {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
}

.NewLandingHero .headerSec {
    padding: 0;
    align-items: end;
    height: 100%;
}

.headerSec>a {
    width: 100%;
    height: 100%;
}

.NewLandingHero .headerSecWrapper {
    width: calc(50% - 5px)
}

.headerSecLink {
    padding: 2% 2%;
    background-color: #00000069;
    width: 100%;
    z-index: -1;
}

.Platform-Catalogue,
.WhoWeAreBg,
.LandingCareer,
.LandingNewsSlider {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 1%;
    box-sizing: border-box;
    /* height: 255px; */
}
.LandingCareer
{
    padding: 0;
}

.Platform-Catalogue {
    background-image: url(../../public/Images/AIEF-Landing.svg);
    background-position: 88% 25%;
    position: relative;
    background-size: 32%;
    background-color: #ABB8AE;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: end;
}

/* @keyframes rotateBackground {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.MovingGlobe
{
    position: absolute;
    top: -80px;
    right: -80px;
    animation: rotateBackground 60s linear infinite;
} */

/* .Platform-Catalogue::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../public/Images/AIEF-Landing.svg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    animation: rotateBackground 10s linear infinite;
} */




.Platform-Catalogue p:nth-child(1) span
{
    font-weight: 300;
}

.Platform-Catalogue,
.WhoWeAreBg {
    width: calc(25% - 7px);
}

.LandingNewsSlider {
    width: calc(28% - 7px);
}

.LandingCareer {
    width: calc(22% - 7px);
}

.LandingNewsSlider {
    background-color: #f1f2f3;
    position: relative;
}

.New_News_Container {
    display: flex;
}

.New_News_Image {
    margin-right: 25px;
}
/* .New_News_Image  img
{
    image-rendering: pixelated !important;
} */
.NewsHead_Details p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px 0px 0px;
    line-height: 1.1;
    font-size: 1.8rem;
    font-weight: 500;
}

.NewsHead_Details p span {
    display: block;
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 15px;
}

.WhoWeAreBg {
    background-image: url(../../public/Images/WhoWeAreBg.svg);
    position: relative;
    background-color: #BBC6CD;
    background-position: 138% 0%;
    background-size: 60%;

    display: flex;
    flex-direction: column;
    justify-content: end;
}

.WhoWeAreButton {
    /* position: absolute;
    bottom: 24px;
    width: 100%; */
    display: flex;
    margin-top: 20px;
}

.WhoWeAreButton a:nth-child(1) {
    margin-right: 15px;
    color:black;
}
.WhoWeAreButton a:nth-child(2) {
    margin-right: 15px;
    background-color:black;
    color:white;
}

.LandingCareer {
    background-image: url(../../public/Images/LandingCareerBg.svg);
    position: relative;
    background-color:#D9D9D9;
    background-position: 95% 15%;
    background-size: 34%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.LandingCareerInner
{
    padding: 4% 4.5%;
}

.LandingCareer a button {
    white-space: wrap;
    text-align: left;
}

.NewOpeningButton {
    font-size: 1.6rem;
    margin-right: 10px;
}

.Platform-CatalogueButtons {
    display: flex;
    margin-top: 20px;
    /* position: absolute;
    bottom: 24px; */
    width: 100%;
}

.CatalogueLink,
.WhoWeAreButtonLink {
    color: white;
    background: transparent;
    font-size: 1.8rem;
    font-weight: 400;
    border: 1px solid white;
    padding: 2% 5%;
    border-radius: 10px;
    text-transform: auto;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
}
.LandingCarrerNewOpening {
   
    margin-top: 25px;
    width: 100%;
    background-color: #00000069;
    color: white;
    border-top: 1px solid #808080;

}
.LandingCarrerNewOpening a 
{
    display: flex;
    align-items: center;
    padding: 4% 4%;

}
.LandingCarrerNewOpening a  svg
{
    margin-left: 15px;
}
.LandingCarrerNewOpening:hover {
    background-color: transparent;
    color: black;
    border-top: 1px solid black;
}

.WhoWeAreButtonLink {
    border: 1px solid black;
    color: #4F4F4F;
}

.Platform-CatalogueButtons a:nth-child(1) {
    margin-right: 15px;
    color: black;
    border: 1px solid black;
}
.Platform-CatalogueButtons a:nth-child(1):hover,
.WhoWeAreButton a:nth-child(1):hover
{
    background-color: white;
    border: 1px solid #C6C6C6;
}
.Platform-CatalogueButtons a:nth-child(2) {
    margin-right: 15px;
    background-color: black;
    color: white;
    border: 1px solid black;
    padding:2% 5%;
}


/* LANDING NEWS SLIDER  */
.LandingNewsSlider .NewsText {
    width: 100% !important;
    margin-left: 22px !important;
    height: auto !important;
}

.LandingNewsSlider .swiper-button-next {
    right: 22px !important;
}

.LandingNewsSlider .swiper-button-prev {
    left: auto !important;
    right: 64.2px;
}

.LandingNewsSlider .swiper-button-next,
.LandingNewsSlider .swiper-button-prev {
    top: 38px !important;
    height: 30px;
    width: 20px;
    z-index: 0;
}

.LandingNewsSlider .swiper-button-next:after {
    background-image: url(../../public/Images/LandingNewsSliderNext.svg);
}

.LandingNewsSlider .swiper-button-prev:after {
    background-image: url(../../public/Images/LandingNewsSliderPrev.svg);
}

.LandingNewsSlider .swiper-button-prev:after,
.LandingNewsSlider .swiper-button-next:after {
    content: "" !important;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.LandingNewsSlider .swiper-button-next {
    padding: 0px 10px !important;
    border-radius: 0px !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border: 2px solid black;
}

.LandingNewsSlider .swiper-button-prev {
    padding: 0px 10px !important;
    border: 2px solid black;
    border-radius: 0px !important;
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}

.LandingNewsSlider .swiper-button-prev.swiper-button-disabled,
.LandingNewsSlider .swiper-button-next.swiper-button-disabled {
    opacity: 0.4 !important;
    pointer-events: auto !important;
}

.SocialLogosSticky {
    display: none !important;
}

.SocialLogos {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    border: 1px solid white;
    padding: 0px 15px;
    height: 180px;
    justify-content: space-evenly;
    background-color: #eaeaea;
}

.SocialLogos svg {
    width: 25px;
    height: 25px;
}

.SocialLogos svg path {
    fill: #838383;
}

.SocialLogos svg:hover path {
    fill: black;
}

.SocialLogosSticky {
    position: sticky;
    top: 50px;
    display: flex;
    justify-content: end;
    margin-top: 0px;
}
.SocialLogosSticky.Stickyback
{
    z-index: 1;
}

.IotSliderText {
    height: 200px;
}

.IotLandingSlider {
    height: 89.5vh;
    overflow: hidden;
}

.IotLandingSliderLink a .IotSliderImage {
    overflow: hidden;
}

.IotLandingSliderLink a .IotSliderImage img {
    transition: all 0.5s ease;
}

.IotLandingSliderLink a .IotSliderImage:hover img {
    scale: 1.1;
}


/* *******************************************************************************
***************************** LANDING PAGE MEDIA QUERIES  ************************************
***************************************************************************************** */

@media only screen and (max-width: 1650px) {

    .New_News_Image img {
        width: 180px;
    }

    .Platform-Catalogue p {
        width: 85%;
    }

    .Platform-Catalogue p {
        width: 66%;
        line-height: 1.1;
    }

    .NewsHead_Details p {
        line-height: 1.1;
    }

    .WhoWeAreBg p:nth-child(2) {
        font-size: 1.8rem;
    }

    .NewsHead_Details p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        /* height: 88px; */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .LandingNewsSlider .swiper-button-next,
    .LandingNewsSlider .swiper-button-prev {
        height: 35px;
    }
    .WhoWeAreBg {
        background-position: 138% 30%;
    }
    .NewLanding {
        height: 88vh;
    }
}

@media only screen and (max-width: 1450px) {

    .NewsHead_Details p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .WhoWeAreBg {
        background-position: 138% 30%;
    }

    .WhoWeAreBg p:nth-child(2) {
        font-size: 1.8rem;
    }

    .NewsHead_Details p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .Platform-Catalogue > p:nth-child(2)  {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        overflow: hidden;
        width: 60%;
    }
      
    
}

@media only screen and (max-width: 1350px) {

    .CatalogueLink,
    .WhoWeAreButtonLink {
        padding: 2% 5% 2% 3%;
    }

    .LandingNewsSlider .swiper-button-next,
    .LandingNewsSlider .swiper-button-prev {
        top: 30px !important;
    }
    
}


@media only screen and (max-width: 1200px) {
    .LandingUpdatesWrapper {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0;
    }

    .Platform-Catalogue,
    .WhoWeAreBg,
    .LandingNewsSlider,
    .LandingCareer {
        width: calc(50% - 5px);
        margin-bottom: 10px;
    }

    .Platform-CatalogueButtons {
        flex-direction: row;
    }

    .Platform-Catalogue,
    .WhoWeAreBg,
    .LandingCareer,
    .LandingNewsSlider {
        padding: 2% 2%;
    }

    .WhoWeAreButton {
        width: 95%;
    }

    .NewLandingHero,
    .NewLanding {
        height: auto;
    }

    .NewLandingHero .headerSec {
        height: 55vh;
    }

    .LandingCareerInner {
        padding: 2% 3%;
        margin-top: 5rem;
    }
    .LandingCarrerNewOpening {
        padding: 2% 3%;
        width:94%;
    }
    .LandingCareer
    {
        padding: 0% 0%;
        background-position: 95% 20%;
        background-size: 25%;
    }
    .Platform-Catalogue p {
        width: 50%;
    }


}
@media only screen and (max-width: 992px) {
    .LandingCareer {
        background-size: 32%;
    }
    .LandingCarrerNewOpening {
        padding: 3% 3%;
    }
}
@media only screen and (max-width: 850px) {

    .New_News_Image img {
        width: 140px;
    }
}

@media only screen and (max-width: 768px) {
    .NewLandingHero {
        flex-direction: column;
    }

    .headerSecWrapper {
        width: 100% !important;
    }

    .NewLandingHero .headerSecWrapper:nth-child(2) {
        margin-top: 20px;
    }
    .LandingCarrerNewOpening {
        padding: 4% 3%;
    }

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .smallVideo {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .smallVideo img {
        width: 85% !important;
    }

    .smallVideo .playBtn img {
        width: auto !important;
    }

    .headerSec {
        padding: 5% 6%;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        color: black;
    }

    .swiper-button-next,
    .swiper-button-prev {
        border: 1px solid #e8e8e8;
        background: white;
    }

    .dunasysnetworksText {
        display: block;
        background-color: black;
        padding: 6% 5% 1% 5%;
    }

    .dunasysnetworksText2 {
        display: none;
    }

    .playBtn {
        display: none !important;
    }

    .videoText p:nth-child(1) {
        font-size: 3rem;
    }

    .videoText p:nth-child(2) {
        margin: 10px 0px 20px 0px;
    }

    .LandingNewsSlider .swiper-button-prev {
        width: 16px;
        right: 60.2px;

    }

    .LandingNewsSlider .swiper-button-next {
        width: 16px;
    }

    .Platform-Catalogue p {
        width: 62%;
    }

    .New_News_Container {
        flex-direction: column;
    }

    .NewsHead_Details p {
        padding: 0px 0px;
        -webkit-line-clamp: 3;
    }

    .New_NewsText>p {
        padding: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .Platform-CatalogueButtons,
    .WhoWeAreButton {
        flex-direction: column;
    }

    .Platform-CatalogueButtons a:nth-child(1),
    .WhoWeAreButton a:nth-child(1) {
        margin-bottom: 10px;
    }

    .WhoWeAreButtonLink,
    .CatalogueLink {
        width: 120px;
    }

    .New_NewsText {
        margin-top: 15px;
    }

    .NewLandingHero .SocialLogos {
        display: none;
    }

    .SocialLogosSticky {
        display: flex !important;
    }
    .LandingCareer {
        justify-content: space-between;
    }
    .LandingCarrerNewOpening {
        padding: 4% 3%;
    }
    .LandingCareerInner a button {
        font-size: 3.5rem;
    }
    .LandingCareerInner p {
        font-size: 2.2rem;
    }

}

@media only screen and (max-width: 460px) {

    .Platform-Catalogue,
    .WhoWeAreBg,
    .LandingNewsSlider,
    .LandingCareer {
        width: calc(100% - 0px);
        margin-bottom: 20px;
        padding: 4% 10%;
    }
    .LandingNewsSlider {
        height: auto !important;;
    }

    .New_News_Image img {
        width: 100%;
    }
    .New_News_Image 
    {
        margin-top: 15px;
        margin-right: 0px;
    }
    .LandingNewsSlider .swiper-button-next, .LandingNewsSlider .swiper-button-prev {
        top: 36px !important;
    }

    .NewsHead_Details p {
        padding: 0px 0px;
        -webkit-line-clamp: 5;
    }

    .NewsHead_Details p {
        width: 100%;
    }

    .Platform-CatalogueButtons {
        width: 96%;
    }
    .Platform-Catalogue p {
        width: 60%;
    }
    
    .LandingCarrerNewOpening {
        width: 80%;
    }
    .Platform-CatalogueButtons, .WhoWeAreButton {
        flex-direction: row;
    }
    .Platform-CatalogueButtons a:nth-child(1), .WhoWeAreButton a:nth-child(1) {
        margin-bottom: 0px;
    }
    .Platform-CatalogueButtons,
 .WhoWeAreButton  {
        margin-top: 40px;
    }
    .LandingCareer
    {
        padding: 0;
    }
    .LandingCareerInner,
    .LandingCarrerNewOpening
    {
        padding: 4% 10%;
    }
    .LandingCareer {
        background-size: 32%;
    }
    .Platform-Catalogue > p:nth-child(1) ,
    .WhoWeAreBg > p:nth-child(1),
    .LandingCareerInner a button,
    .LandingNewsSlider .NewNewsSlider a button
    {
        font-size: 3.5rem;
    }
    .Platform-Catalogue > p:nth-child(2),
    .WhoWeAreBg > p:nth-child(2),
    .LandingCareerInner p,
    .NewOpeningButton,
    .NewsHead_Details p,
    .New_News_Date p
    {
        font-size: 2.2rem;
    }
    .NewsHead_Details p span
    {
        font-size: 2rem;
    }
    .WhoWeAreBg {
        background-position: 138% 54%;
    }
    .headerSecLink button
    {
        font-size: 2.5rem;
    }


}

@media only screen and (min-width: 2020px) {
    .NewLandingHero {
        height: 80%;
    }

    .NewLanding {
        height: 92vh;
    }
    .LandingCareer {
        background-position: 95% 15%;
        background-size: 30%;
    }
    .WhoWeAreBg {
        background-size: 56%;
    }
    .Platform-Catalogue {
        background-size: 26%;
    }
}